<p-confirmDialog [acceptLabel]="'Common.yes' | translate" [header]="'Common.logout' | translate"
                 [rejectLabel]="'Common.no' | translate" icon="pi pi-exclamation-circle" key="logout"></p-confirmDialog>

<div class="layout-menu-container flex">
  <ul
    (keydown)="onKeydown($event)"
    class="layout-menu flex flex-column justify-content-between  directory-list"
    role="menu">
    <div class="flex flex-column">
      <button
        (click)="appMain.toggleMenu()"
        *ngIf="menuActiveMobile"
        class="mt-2 userButton text-gray-900"
        icon="pi pi-times"
        pButton></button>
      <ng-container *ngFor="let item of directory">
        <div class="relative">
          <div class="research-vip-badge" *ngIf="item.baseLink ==='research' && user?.researchVIP">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.7943 17.0347V23.814L12.0009 21.7406L9.20508 23.814V17.0347H14.7943Z" fill="#FFCF40"/>
              <path d="M9.08359 3.3125L6.16797 8.36333L9.08388 13.4147H14.9149L17.833 8.36333L14.9149 3.3125H9.08359Z"
                    fill="#FFCF40"/>
              <path opacity="0.7"
                    d="M9.65523 12.4251L7.31055 8.3634L9.655 4.30225H14.3437L16.69 8.3634L14.3437 12.4251H9.65523Z"
                    fill="url(#paint0_linear_5244_2283)"/>
              <path
                d="M20.7848 7.22462L17.3778 1.32367C16.972 0.621016 16.2149 0.185547 15.4059 0.185547H8.59435C7.7828 0.185547 7.02573 0.621016 6.61993 1.32616L3.21545 7.22462C2.8097 7.92728 2.8097 8.8007 3.21545 9.50336L6.61993 15.4019C7.02568 16.1045 7.7828 16.54 8.59435 16.54H15.4058C16.2149 16.54 16.9719 16.1045 17.3777 15.4019L20.7847 9.50336C21.1905 8.8007 21.1905 7.92728 20.7848 7.22462ZM18.5832 8.54875L15.4508 13.9712C15.3846 14.086 15.2621 14.1568 15.1294 14.1568H8.86979C8.73713 14.1568 8.61465 14.086 8.54841 13.9712L5.4182 8.54875C5.35173 8.434 5.35173 8.29239 5.4182 8.17764L8.54813 2.75566C8.61432 2.64091 8.73685 2.57008 8.86951 2.57008H15.1294C15.2621 2.57008 15.3846 2.64086 15.4508 2.75566L18.5832 8.17764C18.6496 8.29239 18.6496 8.434 18.5832 8.54875Z"
                fill="#FFCF40"/>
              <defs>
                <linearGradient id="paint0_linear_5244_2283" x1="12.0003" y1="4.30225" x2="12.0003" y2="12.4251"
                                gradientUnits="userSpaceOnUse">
                  <stop offset="0.0862" stop-color="white"/>
                  <stop offset="1" stop-color="#FFBF00"/>
                </linearGradient>
              </defs>
            </svg>
          </div>
          <button
            (click)="!item.disabled && openDirectory(item); navigateTo(item)"
            *ngIf="item.display"
            [class.disabled]="item.disabled"
            [ngClass]="{ active: item.active }"
            [ngStyle]="{ background: item.background }"
            [ngxTippy]="!item.disabled ? (item.title | translate) : 'comingSoon' | translate"
            class="directoryButton mt-3"
            pButton
            [tippyProps]="{placement:'right'}"
          >
            <img
              [ngClass]="{ active: item.active}"
              [ngStyle]="{ color: item.color }"
              *ngIf="item.baseLink === 'summit'"
              class="svg-icon no-rotate"
              src="assets/icon/logo-color.svg"/>

            <img
              [ngClass]="{ active: item.active, rotated: item.rotated}"
              [ngStyle]="{ color: item.color }"
              *ngIf="!item.medias && item.baseLink !== 'summit'"
              class="svg-icon"
              src="assets/icon/logo.svg"/>

            <img
              [ngClass]="{ active: item.active, rotated: item.rotated }"
              [ngStyle]="{ color: item.color }"
              *ngIf="item.medias"
              class="svg-icon medias"
              src="assets/icon/play.svg"/>
          </button>
        </div>
      </ng-container>
    </div>
    <div class="flex flex-column justify-content-center align-items-center">

      <!-- <div (click)="openLogoutModal()" class="cursor">
        <ion-icon class="m-0" name="log-out-outline"></ion-icon>
      </div> -->

      <ng-container *ngFor="let item of userMenus">
        <!--        <ion-icon-->
        <!--          (click)="item.action && item.action()"-->
        <!--          *ngIf="item.active && item.icon"-->
        <!--          [ngxTippy]="item.label | translate"-->
        <!--          name="{{item.icon}}"-->
        <!--          [tippyProps]="{placement:'right'}"></ion-icon>-->
        <button
          (click)="item.action && item.action()"
          *ngIf="item.icon"
          [icon]="item.icon"
          [ngClass]="{ active: item.active }"
          [ngStyle]="{ background: item.background }"
          [ngxTippy]="item.label | translate"
          class="mt-2 userButton text-gray-900"
          pButton
          [tippyProps]="{placement:'right'}"></button>
      </ng-container>


      <div (click)="openLanguageModal()" class="mt-2 cursor">
        <img [src]="selectedLang === ELang.FR ? './assets/flag/fr.svg' :'./assets/flag/en.svg'" alt="">
      </div>


      <!--      <ion-icon (click)="toggleDark(true)" *ngIf="!darkMode"-->
      <!--                [ngxTippy]="'tooltip.darkMode' | translate"-->
      <!--                class=" cursor moon" ioniconRemoveTitle name="moon-outline"-->
      <!--                tooltipPosition="bottom"></ion-icon>-->

      <!--      <ion-icon (click)="toggleDark(false)" *ngIf="darkMode"-->
      <!--                [ngxTippy]="'tooltip.lightMode' | translate"-->
      <!--                class=" cursor sun" ioniconRemoveTitle name="sunny"-->
      <!--                tooltipPosition="bottom"></ion-icon>-->


      <!--      {-->
      <!--      label: 'menu.user.logout',-->
      <!--      icon: 'log-out-outline',-->
      <!--      class: 'text-red-600 text-bold',-->
      <!--      routerLink: [''],-->
      <!--      },-->

      <button
        (click)="goToMessenger()"
        class="mt-2 cursor settingsButton p-0 text-gray-900"
      >
        <span *ngIf="hasUnread$ | async" class="unread">{{ hasUnread$ | async }}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24" fill="none">
          <path
            d="M8.6665 3.83333C8.6665 3.21449 8.91234 2.621 9.34992 2.18342C9.78751 1.74583 10.381 1.5 10.9998 1.5C11.6187 1.5 12.2122 1.74583 12.6498 2.18342C13.0873 2.621 13.3332 3.21449 13.3332 3.83333C14.673 4.46687 15.8151 5.45305 16.6372 6.68618C17.4593 7.91932 17.9304 9.35292 17.9998 10.8333V14.3333C18.0876 15.0587 18.3445 15.7532 18.7498 16.3611C19.1551 16.9691 19.6974 17.4733 20.3332 17.8333H1.6665C2.30227 17.4733 2.84461 16.9691 3.24989 16.3611C3.65516 15.7532 3.91204 15.0587 3.99984 14.3333V10.8333C4.06932 9.35292 4.54036 7.91932 5.36245 6.68618C6.18454 5.45305 7.32669 4.46687 8.6665 3.83333"
            stroke="#1A202C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path
            d="M7.5 17.832V18.9987C7.5 19.927 7.86875 20.8172 8.52513 21.4736C9.1815 22.1299 10.0717 22.4987 11 22.4987C11.9283 22.4987 12.8185 22.1299 13.4749 21.4736C14.1313 20.8172 14.5 19.927 14.5 18.9987V17.832"
            stroke="#1A202C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>

      <button
        *ngIf="user$ | async as user"
        (click)="openSettings()"
        class="mt-2 settingsButton p-0 text-gray-900 profilebutton"
        pButton
        >
        <!-- <img src="assets/icon/error.svg" [ngxTippy]="'menu.user.discord' | translate" alt="" *ngIf="!user.discord_id" class="ping"> -->
        <img
          [ngxTippy]="'menu.user.buttonlabel' | translate"
          [tippyProps]="{placement:'right'}"
          [src]="user?.avatar ?? 'assets/icon/exempleAvatar.jpg'"
          class="svg-icon w-full avatar"
        />
      </button>
    </div>
  </ul>

  <ul
    (keydown)="onKeydown($event)"
    class="layout-menu w-full col-auto py-3"
    role="menu"
    [class.hidden]="noMenu"
  >

    <!--h4 class="mb-4">{{openedDirectory.title}}</h4-->

    <li
      *ngFor="let item of openedDirectory.children; let i = index"
      app-menu
      class="layout-menuitem-category"
      role="none"
    >
      <div [attr.aria-label]="item.label" class=" layout-menuitem-root-text">
        {{ item.label | translate }}
      </div>

      <ul role="menu">
        <li
          *ngFor="let child of item.items"
          [index]="i"
          [item]="child"
          app-menuitem
          role="none"
        ></li>
      </ul>
    </li>
  </ul>
</div>
