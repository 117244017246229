<ng-container *ngIf="show">

  <a
    (click)="itemClick($event)"
    *ngIf="(!item.routerLink || item.items) && item.visible !== false"
    [attr.aria-label]="item.label"
    [attr.href]="item.url"
    [attr.tabindex]="0"
    [attr.target]="item.target"
    [ngClass]="item.class"
    [class.disabled]="item.disabled"
    class="px-2"
    pRipple
    role="menuitem"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{exact: true}"
  >
    <ng-container *ngIf="item.label === 'menu.mining.parc'">
      <svg id="Layer_1" enable-background="new 0 0 512 512" height="21" viewBox="0 0 512 512" width="21"
           xmlns="http://www.w3.org/2000/svg" fill="#495057">
        <path
          d="m494.057 262.292c-15.895-38.805-39.042-72.084-56.969-92.342l3.521-3.521c13.65-13.652 13.65-35.865-.001-49.517l-45.52-45.519c-6.613-6.613-15.406-10.255-24.759-10.255s-18.146 3.642-24.759 10.255l-3.52 3.52c-20.257-17.927-53.537-41.075-92.342-56.97-51.952-21.279-99.085-23.646-136.3-6.845-9.378 4.233-14.593 13.907-12.979 24.071 1.62 10.201 9.605 17.81 19.87 18.934 64.666 7.084 113.485 44.961 156.248 86.607-4.998 6.198-7.718 13.851-7.718 21.925 0 3.692.576 7.293 1.669 10.71l-260.019 250.067c-6.66 6.389-10.381 15.048-10.478 24.382-.097 9.372 3.636 18.535 10.24 25.139l28.825 28.827c6.521 6.52 15.533 10.24 24.78 10.24.117 0 .236 0 .354-.001 9.334-.096 17.994-3.815 24.374-10.462l250.079-260.035c3.417 1.094 7.019 1.669 10.71 1.669 8.074 0 15.728-2.72 21.925-7.717 41.646 42.762 79.523 91.582 86.607 156.246 1.124 10.265 8.732 18.251 18.933 19.872 10.013 1.588 19.905-3.748 24.072-12.979 16.802-37.217 14.436-84.348-6.843-136.301zm-256.478-214.736c34.373 14.079 63.7 34.224 81.812 50.017l-20.368 20.368c-35.48-34.522-77.642-68.885-130.151-85.936 24.801.219 49.463 7.669 68.707 15.551zm-172.079 431.809c-1.007 1.047-2.859.719-3.806-.233 0 0-28.825-28.827-28.825-28.827-.955-.955-1.274-2.807-.223-3.815l257.7-247.834 22.998 22.998c.001 0-247.743 257.606-247.844 257.711zm281.732-269.077s-45.52-45.521-45.52-45.521c-1.143-1.143-1.135-3.126 0-4.262 0 0 66.484-66.484 66.485-66.485 1.16-1.16 3.11-1.153 4.263 0l45.52 45.519c1.176 1.175 1.176 3.088.001 4.263 0 0-66.486 66.485-66.486 66.485-1.229 1.197-2.948 1.354-4.263.001zm46.827 2.691 20.369-20.369c15.792 18.111 35.938 47.439 50.017 81.811 7.882 19.245 15.332 43.905 15.55 68.707-17.052-52.508-51.414-94.669-85.936-130.149z"/>
      </svg>
    </ng-container>

    <ion-icon *ngIf="item.label !== 'menu.mining.parc'" style="fill: black;" class="layout-menuitem-icon"
              name="{{ item.icon }}"></ion-icon>
    <ng-container *ngIf="item.label === 'menu.user.kyc'">
      {{ item.label }}
      <span
        *ngIf="user$ | async as user"
        [class.pr-2]="!!item.tooltip" [ngxTippy]="item.tooltip | translate" tooltipPosition="right">
        {{ user.accountType === EAccountType.INDIVIDUAL ? 'KYC' : 'KYB' }}
      </span>
    </ng-container>

    <ng-container *ngIf="item.label !== 'menu.user.kyc'">
    <span
      [class.pr-2]="!!item.tooltip" [ngxTippy]="item.tooltip | translate" tooltipPosition="right">
      {{ item.label | translate }}
    </span>
    </ng-container>

    <span *ngIf="item.badge" class="menuitem-badge">{{ item.badge }}</span>
    <i
      *ngIf="item.items"
      class="pi pi-fw {{ active ? 'pi-angle-up' : 'pi-angle-down' }} ml-auto"
    ></i>
    <span *ngIf="item.label === 'menu.user.messaging' && (hasUnread$ | async) "
          class="unread-dot">{{ hasUnread$ | async }} </span>
  </a>

  <a
    (click)="itemClick(item)"
    *ngIf="item.routerLink && !item.items && item.visible !== false && (item.label === 'menu.user.messaging' ? (hasConversations$ | async) : true)"
    [attr.aria-label]="item.label"
    [attr.tabindex]="0"
    [attr.target]="item.target"
    [ngClass]="item.class"
    [ngxTippy]="!isSmallScreen ? null : item.label | translate"
    [routerLink]="item.routerLink"
    [class.disabled]="item.disabled"
    class="px-2"
    pRipple
    routerLinkActive="active"
    [tippyProps]="{placement:'right'}"
    [routerLinkActiveOptions]="{exact: true}"
  >
    <ng-container *ngIf="item.label === 'menu.mining.parc'">
      <svg id="Layer_1" enable-background="new 0 0 512 512" height="21" viewBox="0 0 512 512" width="21"
           xmlns="http://www.w3.org/2000/svg" fill="#495057">
        <path
          d="m494.057 262.292c-15.895-38.805-39.042-72.084-56.969-92.342l3.521-3.521c13.65-13.652 13.65-35.865-.001-49.517l-45.52-45.519c-6.613-6.613-15.406-10.255-24.759-10.255s-18.146 3.642-24.759 10.255l-3.52 3.52c-20.257-17.927-53.537-41.075-92.342-56.97-51.952-21.279-99.085-23.646-136.3-6.845-9.378 4.233-14.593 13.907-12.979 24.071 1.62 10.201 9.605 17.81 19.87 18.934 64.666 7.084 113.485 44.961 156.248 86.607-4.998 6.198-7.718 13.851-7.718 21.925 0 3.692.576 7.293 1.669 10.71l-260.019 250.067c-6.66 6.389-10.381 15.048-10.478 24.382-.097 9.372 3.636 18.535 10.24 25.139l28.825 28.827c6.521 6.52 15.533 10.24 24.78 10.24.117 0 .236 0 .354-.001 9.334-.096 17.994-3.815 24.374-10.462l250.079-260.035c3.417 1.094 7.019 1.669 10.71 1.669 8.074 0 15.728-2.72 21.925-7.717 41.646 42.762 79.523 91.582 86.607 156.246 1.124 10.265 8.732 18.251 18.933 19.872 10.013 1.588 19.905-3.748 24.072-12.979 16.802-37.217 14.436-84.348-6.843-136.301zm-256.478-214.736c34.373 14.079 63.7 34.224 81.812 50.017l-20.368 20.368c-35.48-34.522-77.642-68.885-130.151-85.936 24.801.219 49.463 7.669 68.707 15.551zm-172.079 431.809c-1.007 1.047-2.859.719-3.806-.233 0 0-28.825-28.827-28.825-28.827-.955-.955-1.274-2.807-.223-3.815l257.7-247.834 22.998 22.998c.001 0-247.743 257.606-247.844 257.711zm281.732-269.077s-45.52-45.521-45.52-45.521c-1.143-1.143-1.135-3.126 0-4.262 0 0 66.484-66.484 66.485-66.485 1.16-1.16 3.11-1.153 4.263 0l45.52 45.519c1.176 1.175 1.176 3.088.001 4.263 0 0-66.486 66.485-66.486 66.485-1.229 1.197-2.948 1.354-4.263.001zm46.827 2.691 20.369-20.369c15.792 18.111 35.938 47.439 50.017 81.811 7.882 19.245 15.332 43.905 15.55 68.707-17.052-52.508-51.414-94.669-85.936-130.149z"/>
      </svg>
    </ng-container>

    <ion-icon *ngIf="item.label !== 'menu.mining.parc'" class="layout-menuitem-icon" name="{{ item.icon }}"></ion-icon>

    <ng-container *ngIf="item.label === 'menu.user.kyc'">
      <span
        *ngIf="user$ | async as user"
        [class.pr-2]="!!item.tooltip" [ngxTippy]="item.tooltip | translate" tooltipPosition="right">
        {{ user.accountType === EAccountType.INDIVIDUAL ? 'KYC' : 'KYB' }}
      </span>
    </ng-container>

    <ng-container *ngIf="item.label !== 'menu.user.kyc'">
      <span>{{ item.label | translate }}</span>
    </ng-container>
    <!-- <ng-container *ngIf="user$ | async as user">
      <ng-container *ngIf="item.label === 'menu.user.profil' && !user?.discord_id">
        <img src="assets/icon/error.svg" [ngxTippy]="'menu.user.discord' | translate" alt=""
             class="ping">
      </ng-container>
    </ng-container> -->
    <span *ngIf="item.badge" class="p-tag p-badge ml-auto">{{
        item.badge
      }}</span>

    <span *ngIf="item.label === 'menu.user.messaging' && (hasUnread$ | async)"
          class="unread-dot">{{ hasUnread$ | async }} </span>
    <!--    <i-->
    <!--      class="pi pi-fw {{ active ? 'pi-angle-up' : 'pi-angle-down' }} ml-auto"-->
    <!--      *ngIf="item.items"-->
    <!--    ></i>-->
  </a>
</ng-container>
